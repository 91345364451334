var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "comments-join-modal",
        "c-community__join--modal",
        { open: _vm.active },
        { close: !_vm.active },
      ],
    },
    [
      _c(
        "div",
        {
          staticClass: "o-grid o-grid--center u-bg-white u-border-radius-large",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "o-grid__item u-width-full u-a-center u-p-t10 u-p-b5 u-p-x6",
            },
            [
              _vm.showCloseButton
                ? _c(
                    "button",
                    {
                      staticClass: "c-community__join--modal__close",
                      on: {
                        click: _vm.close,
                        mousedown: _vm.closeDown,
                        mouseup: _vm.closeUp,
                        touchstart: _vm.closeDown,
                        touchend: _vm.closeUp,
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "24",
                            height: "24",
                            viewBox: "0 0 14 14",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M1 1L13 13",
                              stroke: "black",
                              "stroke-width": "1.5",
                              "stroke-linecap": "round",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M13 1L1 13",
                              stroke: "black",
                              "stroke-width": "1.5",
                              "stroke-linecap": "round",
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c(
                "h4",
                { staticClass: "u-color-blue u-text-24 u-semibold u-m-b2" },
                [_vm._v("Welcome")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "u-m-b3" }, [_vm._v(_vm._s(_vm.message))]),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "c-user-categories" },
                _vm._l(_vm.categories, function (row) {
                  return _c(
                    "tr",
                    _vm._l(row[0], function (value, category) {
                      return _c("td", [
                        _c(
                          "label",
                          {
                            staticClass: "radio",
                            attrs: { "data-cy": "categories" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.chosenCategories,
                                  expression: "chosenCategories",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: value,
                                checked: Array.isArray(_vm.chosenCategories)
                                  ? _vm._i(_vm.chosenCategories, value) > -1
                                  : _vm.chosenCategories,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.chosenCategories,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = value,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.chosenCategories = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.chosenCategories = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.chosenCategories = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(category))]),
                          ]
                        ),
                      ])
                    }),
                    0
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "c-btn c-btn--red c-btn--join is-outline",
                  on: { click: _vm.close },
                },
                [_vm._v("Close")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "c-btn c-btn--join",
                  attrs: { "data-cy": "save-categories" },
                  on: { click: _vm.savePreferences },
                },
                [_vm._v("Save")]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-community__join--community" }, [
      _c("img", {
        attrs: {
          src: "/img2.0/icons/logo-symbol-dyadey.svg",
          alt: "Community Logo",
          width: "64",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }