<template>
    <a :href="'/' +  community.url" :class="{ 'active' : !isHovered }" @mouseenter="isHovered = true" @mouseleave="isHovered = false">
        <img :src="'https://res.cloudinary.com/dyadey/image/upload/c_fill,f_auto,q_auto,g_center,dpr_1.0,w_' + width + ',h_' + height + '/v1/i/' + community.thumb" alt="" class="image">
        <img :src="$simpleStore.cloudinaryUrl(logoDims, logoDims, 'img/communities/' + community.community_id + '/' + community.image, $root)" alt="" class="logo" :width="logoDims" :height="logoDims">
        <div v-html="community.name" :class="['name', height >= width ? 'portrait' : '']" class="name"></div>
    </a>
</template>

<script>
export default {
    props: {
        linkClass: {
            type: String,
            default: 'active'
        },
        height: Number,
        width: Number,
        reset: Boolean,
        logoDims: {
            type: Number,
            default: 54
        }
    },
    data() {
        return {
            community: null,
            count: 0,
            hover: false,
            isHovered: false,
            trendingThumbnails: this.$root.trendingThumbnails,
        }
    },
    created() {
        if (this.reset) {
            this.$simpleStore.heroCount = 0;
        }
        this.community = this.getCommunity();
    },
    methods: {
        getCommunity() {
            return this.trendingThumbnails[this.$simpleStore.heroCount++];
        }
    }
}
</script>
