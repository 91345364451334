<template>
    <div :class="['comments-join-modal', 'c-community__join--modal', { 'open': active }, { 'close': !active }]">
        <div class="o-grid o-grid--center u-bg-white u-border-radius-large">
            <div class="o-grid__item u-width-full u-a-center u-p-t10 u-p-b5 u-p-x6">
                <button v-if="showCloseButton"
                        class="c-community__join--modal__close"
                        @click="close"
                        @mousedown="closeDown"
                        @mouseup="closeUp"
                        @touchstart="closeDown"
                        @touchend="closeUp">
                    <svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 1L13 13"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                        />
                        <path
                            d="M13 1L1 13"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                        />
                    </svg>
                </button>
                <div class="c-community__join--community">
                    <img src="/img2.0/icons/logo-symbol-dyadey.svg" alt="Community Logo" width="64">
                </div>
                <h4 class="u-color-blue u-text-24 u-semibold u-m-b2">Welcome</h4>
                <p class="u-m-b3">{{ message }}</p>
                <table class="c-user-categories">
                    <tr v-for="row in categories">
                        <td v-for="(value, category) in row[0]">
                            <label class="radio" data-cy="categories">
                                <input type="checkbox" v-model="chosenCategories" :value="value">
                                <span>{{ category }}</span>
                            </label>
                        </td>
                    </tr>
                </table>
                <button class="c-btn c-btn--red c-btn--join is-outline" @click="close">Close</button>
                <button class="c-btn c-btn--join" @click="savePreferences" data-cy="save-categories">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['channel'],
    data() {
        return {
            active: false,
            chosenCategories: [],
            categories: [
                [{ 'Business': 25, 'Celebrities': 266, 'Fashion': 261 }],
                [{ 'Games': 28, 'Movies': 24, 'Music': 21 }],
                [{ 'Sport': 16, 'Technology': 54, 'Television': 22 }]
            ],
            message: 'We can show you more communities and quality content based on the topics you pick below.',
            showCloseButton: true
        }
    },
    created() {
        if (this.$root.showUserCategories === true) {
            this.active = true;
            if (this.channel === 'email') {
                this.message  = 'Thank you for verifying your email - you will shortly receive a welcome email.  ' + this.message;
            } else if (this.channel === 'facebook') {
                this.message  = 'Thank you for registering using Facebook - you will shortly receive a welcome email.  ' + this.message;
            }
        }
    },
    mounted() {

    },
    methods: {
        closeDown(event) {
            document.querySelector('.c-community__join--modal__close').classList.add('click-down');
        },
        closeUp(event) {
            document.querySelector('.c-community__join--modal__close').classList.replace('click-down', 'click-up');
        },
        close(noRequest) {
            // delay close by 200ms to allow close animation to complete
            this.active = false;
            if (typeof noRequest != 'undefined' && noRequest === true) {
                return true;
            }
            // send welcome email without setting any preferences
            this.$http.get('/api/v1/no-save-prefs').then(response => {
                console.log('Welcome email sent');
            }, response => {
                console.log('Errored:');
                console.log(response);
            });
        },
        savePreferences() {
            let parameters = {
                categories: this.chosenCategories
            };
            this.$http.post('/api/v1/save-prefs', parameters).then(response => {
                if (response.data.success === true) {
                    sessionStorage.removeItem('explore');
                    this.close(true);
                    window.location = '/';
                }
            }, response => {
                console.log('Errored:');
                console.log(response);
            });
        }
    }
}
</script>
